.content,
.commentContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin-top: 5px;
  padding-left: 28px;
  height: calc(100% - 40px);
  h2,
  p {
    margin: 0;
  }
}
.leadNameHeatbox {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  span {
    font-size: 13px !important;
    font-weight: bold;
  }
}
@media (max-width: 1690px) {
  .leadNameHeatbox {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    span {
      font-size: 11px !important;
      font-weight: normal;
    }
  }
}
.commentContent {
  padding-left: 28px;
  margin-right: 5px;
}

.prospectInfo,
.prospectRepost,
.commentInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.prospectInfo {
  // padding-bottom: 40px;
}

.prospectRepost {
  gap: 5px;
}

.commentInfoExpanded {
  display: flex;
  flex-direction: column;
  gap: 2px;
  // margin-bottom: 60px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
}

.postInCommentExpanded {
  margin-bottom: 20px;
}

.commentInfo {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  // margin-bottom: 60px;
}

.post {
  display: flex;
  flex-direction: column;
  gap: 8px;
  // max-height: 70%;
  // height: 50%;
  // overflow-y: auto;
  // &::-webkit-scrollbar {
  //   width: 1px !important;
  // }
  span {
    font-size: 16px;
  }
}

.stamp {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 57%;
  bottom: 23%;
  z-index: 10;
}

.source {
  font-size: 12px;
  color: #888;
  display: flex;
  align-items: center;
  gap: 3px;
  margin: 0;
  .platformIcon {
    width: 25px;
    height: 25px;
  }
}

.name {
  font-size: 18px;
  font-weight: bold;
}

.timestamp {
  font-size: 12px;
  color: #aaa;
}

.title {
  font-size: 14px;
  color: #555;

  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds "..." at the end */
  width: 100%; /* Ensures it takes full width */
  display: block; /* Ensures block behavior */
}

.topicWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.topic {
  display: flex;
  align-items: center;
  background: #141414;
  color: white;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 12px;
  width: fit-content;
  gap: 3px;
  img {
    margin-left: -5px;
    height: 10px;
    width: 18px;
  }
}

.score {
  color: #4caf50;
  font-size: 14px;
  background-color: #cff7d3;
  padding: 3px 6px;
  border-radius: 4px;
  font-weight: 400;
}

.postTitle {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.details {
  list-style: none;
  padding: 0;
  margin: 0;
}

.details li {
  font-size: 14px;
  margin: 4px 0;
  color: #5b5e63;
}

.reactions {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #8965fb;
  background-color: #ebebeb;
  width: fit-content;
  padding: 2px 10px 2px 6px;
  border-radius: 20px;
  gap: 5px;
  // text-decoration: underline;
  img {
    cursor: default !important;
  }
}

.showMore {
  color: #7043ff;
  cursor: pointer;
  font-size: 14px !important;
  font-weight: bold;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.exportIcon {
  margin-left: 4px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.commentWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: auto;

  img {
    height: 90px;
    width: 14px;
  }

  .commentText {
    padding: 10px;
    margin-left: 5px;
    height: 100%;
    width: 100%;
    border: 5px solid #7043ff;
    border-radius: 10px;
    box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.15);

    .topSection {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 5px;

      .topSectionLeft {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }

      .title {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-weight: bold;
          font-size: 14px;
          color: #000;
          margin: 0;
        }

        .personaBadge {
          display: flex;
          align-items: center;
          gap: 4px;
          background: #e6f7e6;
          color: #2a9d2a;
          font-size: 12px;
          font-weight: 600;
          padding: 3px 6px;
          border-radius: 4px;

          img {
            width: 14px;
            height: 14px;
          }
        }

        .persona-0 {
          background-color: #f7cfcf;
          color: #7a0000;
        }
        .persona-25 {
          background-color: #f7f6cf;
          color: black;
        }
        .persona-50 {
          background-color: #eef7cf;
          color: black;
        }
        .persona-75 {
          background-color: #cff7d3;
          color: #014c36;
        }
      }

      .subTitle {
        p {
          font-size: 12px;
          color: black;
          margin: 0;
        }
      }

      .topSectionRight {
        align-items: flex-start;
        font-size: 12px;
        color: #999;
      }
    }

    .bottomSection {
      font-size: 13px;
      color: #5b5e63;
      line-height: 1.4;
    }
  }
}

.commentBadge {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 6px;
  background-color: #ffbe9e;

  img {
    // width: 13px;
    height: 13px;
  }
}
.commentBadge {
  color: #014c35;
  img {
    margin: 1px 0px;
  }
}

.repostIcon {
  background-color: #bee4f9;
  padding: 3px 6px;
  border-radius: 4px;
}

.profilePictureWrapper {
  position: relative;
  margin-left: -25px;
  img {
    margin-top: 8px;
    margin-right: 3px;
    width: 45px;
    height: 45px;
  }

  .commentLine {
    position: absolute;
    img {
      height: 100px;
      margin-right: 0px !important;
      margin-left: 4px;
      // width: 100%;
    }
  }
}

.profileText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 90%;
}

.prospectRepost {
  flex-direction: row;
}

.actionsWrapper {
  display: flex;
  flex-direction: row;
}

.customCommentWrapper {
  display: flex;
}

.videoWrapper {
  video {
    border-radius: 8px;
    width: 80%;
    max-height: 250px;
    min-height: 250px;
  }
}
.fireBadge,
.personaBadge {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 6px;
  font-weight: 600;
  img {
    // width: 13px;
    height: 13px;
  }
}
.personaBadge {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 6px;
  font-weight: 600;
  img {
    // width: 13px;
    height: 13px;
  }
}
.personaBadge {
  color: #014c35;
  img {
    margin-bottom: 1px;
  }
}

.fire-2 {
  background-color: #dcf999;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  position: relative; // Ensure absolutely positioned children are contained
  width: 100%;
  overflow: hidden; // Clip any overflowing content
}

.repostTextWrapper {
  padding-left: 10px;
  // max-height: 100px;
  // height: 100px;
  // overflow-y: auto;
  p,
  span {
    font-size: 16px;
  }
}

.customCommentWrapper {
  position: sticky;
  bottom: 33px;
  z-index: 2;
  background-color: white;
  width: calc(100% + 10px);
}

.actionsWrapper {
  position: absolute;
  bottom: 0;
  z-index: 23;
  background-color: white;
  width: 55%;
  min-height: 40px;

  position: sticky;
  width: 100%;
  // // padding-right: 10px;
  // // padding: 8px 0px;
  // // padding-top: 8px;
  // // padding-bottom: 8px;
}

.fixedDiv {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 10px;
  bottom: -9px;
  z-index: 1;
}

.prospectRepostWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.infoCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  span {
    margin-top: 1px;
    font-size: 16px !important;
    color: #5b5e63;
    cursor: pointer;
    &:hover {
      opacity: 0.65;
    }
  }
}

.flexRowDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.persona-0 {
  background-color: #f7cfcf;
  color: #7a0000;
}
.persona-25 {
  background-color: #f7f6cf;
  color: black;
}
.persona-50 {
  background-color: #eef7cf;
  color: black;
}
.persona-75 {
  background-color: #cff7d3;
  color: #014c36;
}
