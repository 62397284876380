.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* centers horizontally */
  align-items: flex-start; /* centers vertically */
  width: 80%;
  height: 100%;
  max-height: 80% !important;
  //   overflow: auto;
  gap: 15px;
  padding: 1rem 0 0 0;
}

.table {
  :global(.ant-table.ant-table-small .ant-table-tbody > tr > td) {
    background-color: white;
  }
  :global(.ant-table-thead > tr > th) {
    // background-color: #f9fafb !important;
    background-color: #f3f4f6 !important;
  }
  :global(.ant-table-wrapper .ant-table-thead > tr > td) {
    // background-color: #f9fafb !important;
    background-color: #f3f4f6 !important;
  }
  :global(.ant-table-thead > tr > td) {
    // background-color: #f9fafb !important;
    background-color: #f3f4f6 !important;
  }
  :global(tr.ant-table-expanded-row > td) {
    // background-color: #f9fafb !important;
    background-color: #f3f4f6 !important;
  }

  :global(.ant-table-pagination.ant-pagination) {
    position: relative !important;
    bottom: 0px !important;
    border-radius: 0px 0px 4px 4px;
    padding: 10px 0px;
    // left: 0 !important;
    // right: 0 !important;
    // margin: 0 auto !important;
    background-color: white;
  }
}

.people {
  display: flex;

  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  p {
    margin: 0;
    font-weight: 600;
  }
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    cursor: pointer;
    &:hover {
      opacity: 0.65;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  //   justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 20px 10px;
  border-radius: 8px;
  gap: 10px;

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
}

.headerSelect {
  :global(.ant-select-selector) {
    border: none !important;
    background-color: #f3f4f6 !important;
    color: black !important;
  }
}

.startSequence {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height: fit-content;
  img {
    width: 30px;
    height: 30px;
    // margin: 3px 0px -3px 0px;
    cursor: pointer;
    margin: 3px 0px;
  }
}

.startSequenceWrapper {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.channels {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  img {
    height: 20px;
    width: 20px;
  }
  p {
    margin: 0;
  }
}

.skeleton {
  :global(.ant-skeleton-title) {
    margin: 10px 0px !important;
  }
}

.exportIcon {
  width: 13px !important;
  height: 13px !important;
  cursor: pointer;
  margin-left: 3px;
}

.fireBadgeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .fireBadge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-size: 14px;
    gap: 4px;
    border-radius: 4px;
    padding: 2px 6px;
    font-weight: 600;
    img {
      height: 13px;
    }
  }

  .fire-1 {
    background-color: #98eff9;
  }

  .fire-2 {
    background-color: #dcf999;
  }

  .fire-3 {
    background-color: #f9e298;
  }

  .fire-4 {
    background-color: #f9b898;
  }

  .fire-5 {
    background-color: #f99898;
  }
}

.score {
  color: #4caf50;
  font-size: 14px;
  background-color: #cff7d3;
  padding: 3px 6px;
  border-radius: 4px;
  font-weight: 400;
  margin: 0;
}
