.container {
  flex: 1;
  max-width: 40%;
  background: #f5f5f5;
  border-radius: 20px 0px 0px 20px;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &::-webkit-scrollbar {
    width: 0px !important;
  }
}

.box,
.metricsBox {
  background: #fff;
  padding: 10px 10px 10px 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  position: relative;
}

.commentBox {
  background: #fff;
  padding: 10px 10px 10px 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to bottom, #7043ff, #fe50e6);
    border-radius: 8px 0 0 8px;
  }
}

.borderedBox {
  background: #fff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  border: 2px solid #091e42;
}

.rowDivider {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  height: 100%;

  .divider {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.colDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  //   min-width: 45%;
  width: fit-content;

  span,
  p {
    margin: 0;
  }

  p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .spanTitle {
    font-size: 14px;
    font-weight: 600;
  }

  .spanValue {
    font-size: 18px;
    background-color: #7043ff;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.topPersonas {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.metricsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; // Changed from center to flex-start
  gap: 16px; // Added consistent gap
  height: auto;
  padding: 10px; // Added padding for consistent spacing
  width: 100%;
  border: 1px solid #777777;
  &::before {
    background: white;
  }

  .leftSide {
    display: flex;
    flex-direction: column;
    gap: 12px; // Increased gap for better spacing
    min-width: 45%; // Added min-width for consistent distribution

    height: 100%;

    .title {
      p {
        margin: 0;
        font-size: 13px;
        color: #5e6c84;
        font-weight: 600;
      }
    }

    .metrics {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px; // Added consistent gap
      width: 100%; // Added width for full span
      height: 100%;

      .metric {
        gap: 8px; // Increased gap for better spacing
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start; // Changed from center
        flex: 1; // Added to distribute space evenly

        p,
        span {
          font-size: 13px;
          margin: 0;
        }

        p {
          font-weight: 600;
          font-size: 16px;
        }

        img {
          background-color: #d9d9d9;
          padding: 2px;
          border-radius: 4px;
          width: 20px;
          height: 20px;
        }

        .metricIcon {
          background-color: #ffdc75;
          padding: 0px 4px;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 16px !important;

          img {
            background-color: transparent;
            width: 20px;
            height: 20px;
            margin-left: -4px;
          }
        }
      }

      .rowMetrics {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 12px;
      }
    }
  }

  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1px;
    height: 100%;
  }

  .rightSide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; // Changed from space-between
    // min-width: 45%; // Added min-width for consistent distribution

    gap: 12px; // Added consistent gap

    .title {
      p {
        margin: 0;
        font-size: 13px;
        color: #5e6c84;
        font-weight: 600;
      }
    }

    .tones {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        font-size: 14px;
        color: black;
        padding: 4px 8px;
        border-radius: 4px;
      }
    }
  }
}

.problemBox {
  display: flex;
  flex-direction: column;
  gap: 14px;
  background: #fff;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  border: 1px solid #777777;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      font-size: 14px;
      color: black;
      font-weight: 600;
    }
    span {
      height: fit-content;
      line-height: 0;
      cursor: pointer;
      color: #7043ff;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .scores {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
  }

  .titleDescription {
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
    }
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .expanded {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 5px;
    margin-top: 10px;
  }
}

.competitorsBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  //   margin: 10px 0px;
  border: 1px solid #777777;

  background: #fff;
  padding: 10px 10px 10px 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  position: relative;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #5e6c84;
  }
  .competitors {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap */
    gap: 8px;
    align-items: center;
    justify-content: flex-start; /* Keeps items aligned to the left */
  }

  .competitors span {
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap; /* Prevents breaking inside the span */
  }
}

.commentBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &::before {
    background: linear-gradient(to bottom, #af52de, rgba(112, 67, 255, 0.51));
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
    }
    img {
      height: 18px;
      width: 18px;
    }
  }

  .send {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    img {
      cursor: pointer;
      &:hover {
        opacity: 0.65;
      }
    }
  }
}

.boxesRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.generateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.generateContainer {
  Button {
    padding: 0 4px;
    height: fit-content;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    border: none;
    box-shadow: none;
  }
}
