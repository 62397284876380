.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // padding: 1rem;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(200, 188, 255, 0.15) 2px, transparent 0);
  background-size: 25px 25px;
  position: relative;
  overflow-y: auto;
}

// Left Section - Expands when AI Summary is open
.leftSider {
  width: 20%;
  min-width: 160px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease-in-out;
  // padding-bottom: 80px;
  z-index: 10;

  &.expanded {
    width: 30%;
  }
}

// Center Section - Shifts right instead of shrinking
.content {
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  transition: transform 0.3s ease-in-out;
  width: 70%;
  position: relative;

  &.shifted {
    // transform: translateX(10%);
  }
}

// Right Section - Shrinks when AI Summary is open
.rightSider {
  width: 20%;
  min-width: 160px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease-in-out;

  &.shrunk {
    width: 10%;
    min-width: 0px;
  }

  img {
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin-left: 16px;
  }
}

.ellipseBlur {
  position: absolute;
  top: -10%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-left: -5%;
  margin-right: -5%;
  width: 110%;
  height: 75%;
  // background: radial-gradient(ellipse, rgba(128, 0, 255, 0.6), rgba(128, 0, 255, 0.2), transparent);
  filter: blur(40px);
  border-radius: 45%;
}

.heatbox {
  background: radial-gradient(
    ellipse,
    rgb(255, 145, 67, 1),
    rgba(255, 145, 67, 1),
    rgba(255, 145, 67, 0.2),
    rgba(255, 145, 67, 0.6),
    rgba(255, 145, 67, 0.1),
    rgba(255, 145, 67, 0.2),
    rgba(255, 145, 67, 0.1)
  );
}

.cues {
  background: radial-gradient(
    ellipse,
    rgba(112, 67, 255, 1),
    rgba(112, 67, 255, 1),
    rgba(112, 67, 255, 0.2),
    rgba(112, 67, 255, 0.6),
    rgba(112, 67, 255, 0.1),
    rgba(112, 67, 255, 0.2),
    rgba(112, 67, 255, 0.1)
  );
}

.seeds {
  background: radial-gradient(
    ellipse,
    rgb(32, 186, 109, 1),
    rgba(32, 186, 109, 1),
    rgba(32, 186, 109, 0.2),
    rgba(32, 186, 109, 0.6),
    rgba(32, 186, 109, 0.1),
    rgba(32, 186, 109, 0.2),
    rgba(32, 186, 109, 0.1)
  );
}

.seedsContainer {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(200, 188, 255, 0.15) 2px, transparent 0);
  background-size: 25px 25px;
  // position: relative;
  // overflow-y: auto;
}
