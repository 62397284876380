.container {
  width: fit-content;
  padding: 12px;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  padding: 1px;
  background: linear-gradient(90deg, #7043ff, #fe50e6);
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

// Add an inner container for the white background
.container::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background: white;
  border-radius: 10px;
  z-index: 0;
}

.open {
  width: 320px;
  // background: white;
  padding: 16px;
  top: 3.5%;
  height: 74%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.closed {
  width: 140px;
  height: 121px;
  // background: white;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 80px;
}

.header,
.content,
.closeButton {
  position: relative;
  z-index: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
}

.title {
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
}

.closeButton {
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #7043ff;
  height: 5px;
  width: 25px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 2;
}

.content {
  flex-grow: 1; /* Allows it to take available space */
}

.visible {
  max-height: 100%; /* Ensures it respects the parent’s height */
  opacity: 1;
  transform: translateY(0);
  overflow-y: auto; /* Ensures scrolling when needed */
  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 8px; /* Increase space between list items */
}

.closed:hover {
  //   transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.closed:hover .header {
  animation: aiPulse 0.6s infinite alternate;
}

// .closed:hover .header img {
//   animation: aiPulse 0.6s infinite alternate;
// }

@keyframes aiPulse {
  0% {
    transform: scale(1);
    opacity: 0.85;
  }
  100% {
    transform: scale(1.03);
    opacity: 1;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 4px;

  h3 {
    margin-bottom: 4px;
  }

  span {
    font-size: 12px;
    color: #777777;
  }
  p {
    font-size: 16px;
    font-weight: 600;
  }

  .action {
    background-color: #e9ddff;
    width: fit-content;
    padding: 3px 6px;
    border-radius: 4px;
  }
}

.metricIcon {
  background-color: #ffdc75;
  padding: 3px 6px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px !important;
  width: fit-content;
  margin-bottom: 2px;
  img {
    background-color: transparent;
    width: 20px;
    height: 20px;
    margin-left: -4px;
  }
}
