.photoGridWrapper {
  display: flex;
  gap: 2px;
  justify-content: center;
  // width: 100%;
  max-height: 100%;
  overflow: hidden; // Prevent content from escaping
}

.imageContainer {
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  // width: 100%;
  img {
    border-radius: 4px !important;
    // height: max-content !important;
    // width: 200px !important;
  }
  // :global(.ant-image) {
  //   width: 100% !important;
  // }
}

.visiblePhoto {
  position: relative;
  // width: 100%;
}

.hiddenPhoto {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s;
}

.imageContainer:hover .overlay {
  z-index: -2;
}
