.container {
  display: flex;
  flex-direction: column;
  // gap: 12px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.firstRow,
.secondRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.secondRow {
  position: absolute;
  top: 80px;
  gap: 60px;
}

.actionButton {
  width: 156px;
  justify-content: center;
}
.actionButtonLike {
  width: 156px;
  justify-content: center;
}
.actionButtonLike {
  background: white;
  color: #1d1b20;
  border: 1px solid #b3b3b3;
  padding: 12px 18px;
  border-radius: 24px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s ease-in-out;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  min-height: 46px;

  img {
    height: 20px;
    width: 20px;
  }

  &:hover {
    // background: rgb(235, 235, 235) !important;
    background: white !important;
  }
}
.actionButton,
.roundActionButton,
.roundBigActionButton,
.roundSmallActionButton {
  background: white;
  color: #1d1b20;
  border: 1px solid #b3b3b3;
  padding: 12px 18px;
  border-radius: 24px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s ease-in-out;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  min-height: 46px;

  img {
    height: 20px;
    width: 20px;
  }

  &:hover {
    // background: rgb(235, 235, 235) !important;
    background: white !important;
  }
}

.roundActionButton,
.roundSmallActionButton,
.roundBigActionButton {
  border-radius: 50%;
  padding: 20px;
  border: none;

  img {
    height: 26px;
    width: 26px;
  }
}
.roundBigActionButton {
  padding: 22px;

  img {
    height: 22px;
    width: 22px;
  }
}

// // // //

.suggestionsList {
  position: absolute;
  left: 15%;
  width: 80%;
  margin-top: 8px;
  border-radius: 8px;
  list-style: none;
  padding: 0;
  z-index: 100001;
  opacity: 0;

  .bold {
    color: #7043ff;
    font-weight: 600;
  }
}

.suggestionsList li {
  padding: 12px 0;
  cursor: pointer;
  margin: 0;
}

.roundSmallActionButton {
  position: absolute;
  right: -65px;
  padding: 10px;
  min-width: 40px;
  min-height: 40px;
  img {
    width: 20px;
    height: 20px;
  }
}
.verificationIcon {
  position: relative;
  width: 24px;
  height: 24px;
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background-color: #00bfa5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
